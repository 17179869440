'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import { Icon, IconName } from '~/components/core/Icon';
import { colors, spacing } from '~/utils/tailwindUtils';
import { Flex } from '@chakra-ui/react';
import { twMerge } from 'tailwind-merge';
import { COUNTRY_PHONE_LIST } from '~/constants';
import { findCountryByPhone } from '~/utils/generalUtils';

type PhoneInputProps = {
  value: string;
  onChange: (value: string) => void;
  name?: string;
  placeholder?: string;
};

/**
 * Phone input component that allows the user to input a phone number
 * @param value
 * @param onChange
 * @param name
 * @param placeholder
 * @returns PhoneInput component
 */

const PhoneInput = ({
  value,
  onChange,
  name,
  placeholder,
}: PhoneInputProps) => {
  // Find the default country based on the phone number value provided as a prop
  const defaultCountry = useMemo(() => findCountryByPhone(value), [value]);
  // Store the initial value to reset the input value
  const initialValue = value;
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [inputValue, setInputValue] = useState(
    value.replace(selectedCountry.code, '')
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleCountryChange = (country: {
    id: number;
    value: string;
    label: string;
    flagUrl: string;
    code: string;
  }) => {
    const newValue = value.replace(selectedCountry.value, '');
    onChange(country.code + newValue);
    setSelectedCountry(country);
    setDropdownOpen(false);
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const isEmpty = value === '';
    const isNumberOrSpace = new RegExp(/^[0-9\s]*$/).test(value);
    if (isNumberOrSpace) {
      onChange(selectedCountry.code + `${value}`);
    }
    if (isEmpty) {
      onChange('');
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    // Use setTimeout to delay closing the dropdown ,otherwise it will close immediately
    setTimeout(() => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setDropdownOpen(false);
      }
    }, 250);
  };

  // Close dropdown when clicked outside the component
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // If the value is the same as the initial value, reset the input value, MOSTLY FOR RESET FORM
    if (initialValue === value) {
      setInputValue(initialValue.slice(selectedCountry.code.length));
      setSelectedCountry(defaultCountry);
    } else {
      setInputValue(value.replace(selectedCountry.code, ''));
    }
  }, [value, selectedCountry, defaultCountry, initialValue]);

  return (
    <Flex className="relative gap-x-0.5">
      <div className="relative inline-block w-36 h-10" ref={dropdownRef}>
        {/* Display selected country (flag + code) */}
        <div
          className="flex items-center justify-between bg-gray-50 text-gray-900 text-sm rounded-l-lg p-2 cursor-pointer h-10 hover:bg-gray-100"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <div className="flex items-center">
            <Icon
              name={
                IconName[
                  (selectedCountry.flagUrl as keyof typeof IconName) || 'DE'
                ]
              }
              size={spacing[5]}
              colorMode="fill"
              className="rounded-full"
            />
            <span className="ml-1">{selectedCountry.code}</span>
          </div>
          <Icon
            name={IconName.ChevronDown}
            color={colors.gray[600]}
            width={24}
            height={24}
          />
        </div>
      </div>

      {/* Dropdown options */}
      {dropdownOpen && (
        <ul className="absolute z-90 mt-10 w-full bg-white border border-r-0 rounded-lg shadow-lg max-h-60 overflow-auto z-10 h-40 ">
          {COUNTRY_PHONE_LIST.map((country) => (
            <li
              key={country.id}
              onClick={() => handleCountryChange(country)}
              className="flex items-center p-2 cursor-pointer hover:bg-gray-100 "
            >
              <Icon
                color={colors.green[800]}
                name={IconName[country.flagUrl as keyof typeof IconName]}
                width={24}
                height={24}
                colorMode="fill"
              />
              <span className="w-10 text-sm ml-1">{country.code}</span>
              <span className="ml-4">{country.label}</span>
            </li>
          ))}
        </ul>
      )}

      {/* Input for phone number */}
      <input
        onChange={handleInputChange}
        name={name}
        id={name}
        type="text"
        placeholder={placeholder || '(555) 987-6543'}
        className={twMerge(
          'block w-full max-w-256  rounded-r-md border-0 bg-gray-50 py-1.5 pl-4 text-gray-900  placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6 outline-none hover:bg-gray-100'
        )}
        value={inputValue}
      />
    </Flex>
  );
};

export default PhoneInput;
