import { twMerge } from 'tailwind-merge';

export interface FormFieldProps {
  onChange?: (e: any) => void;
  label?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  placeholder?: string;
  classNames?: string;
  isDisabled?: boolean;
  isPassword?: boolean;
}
/**
 * TextInput component that allows the user to input a text value in a form
 * @param name
 * @param placeholder
 * @param onChange
 * @param onBlur
 * @param value
 * @param isDisabled
 * @param classNames
 * @returns TextInput component
 */

export const TextInput = ({
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  isDisabled,
  classNames,
  isPassword,
}: Omit<FormFieldProps, 'label'>) => {
  return (
    <input
      name={name}
      id={name}
      type={isPassword ? 'password' : 'text'}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={isDisabled}
      className={twMerge(
        'bg-gray-50 hover:bg-gray-100 h-10 text-black border border-transparent rounded-lg text-base md:text-sm font-medium w-full max-w-256 focus:outline-none focus:border-gray-300 px-5 py-2 placeholder:text-xs',
        classNames
      )}
    />
  );
};
