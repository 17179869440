'use client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Flex from '../flex/Flex';

export interface FormFieldProps {
  onChange: (e: string) => void;
  value: string;
  name?: string;
  classNames?: string;
}

/**
 * Counter component that is used to display a counter with a plus and minus button to increase and decrease the counter value respectively
 * @param name - The name of the counter
 * @param onChange - The function to call when the counter value is changed
 * @param value - The value of the counter
 * @param classNames - The classes to be applied to the counter
 * @returns Counter component
 */

export const Counter = ({
  name,
  onChange,
  value,
  classNames,
}: Omit<FormFieldProps, 'label'>) => {
  const firstRender = useRef(true);
  const [inputValue, setInputValue] = useState(value);
  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const isEmpty = value === '';
    if (value && Number(value)) {
      setInputValue(value);
    }
    if (isEmpty) {
      setInputValue('');
    }
  };

  // Set the input value to the value prop when the value prop changes (initial value)  and update the value on change of the input value
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setInputValue(value);
  }, [value]);

  // Call the onChange function with the input value when the input value changes
  useEffect(() => {
    onChange(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleIncrease = useCallback(() => {
    setInputValue((prev) => {
      // onChange((+prev + 1).toString());
      return (+prev + 1).toString();
    });
  }, []);

  const handleDecrease = useCallback(() => {
    setInputValue((prev) => {
      if (+prev > 1) {
        return (+prev - 1).toString();
      }
      if (+prev === 1) {
        return '';
      }
      return prev;
    });
  }, []);

  return (
    <Flex className="align-middle gap-x-1">
      <Flex
        className="w-12 bg-white rounded-full justify-center items-center border border-gray-100 cursor-pointer md:h-9 md:w-9 "
        onClick={handleDecrease}
      >
        -
      </Flex>
      <input
        name={name}
        type={'text'}
        value={inputValue}
        onChange={handleOnChange}
        className={twMerge(
          'bg-gray-50 text-black focus:ring-1 focus:ring-inset focus:ring-gray-300 rounded-lg text-center text-base md:text-sm font-medium h-9 md:w-16 w-full focus:outline-none focus:border-gray-300 p-2',
          classNames
        )}
      />
      <Flex
        className="md:h-9 md:w-9 w-12 bg-white rounded-full justify-center items-center ml-1 border border-gray-100 cursor-pointer"
        onClick={handleIncrease}
      >
        +
      </Flex>
    </Flex>
  );
};
