'use client';

import { memo, useCallback } from 'react';
import { useI18n } from '~/locales/client';
import { twMerge } from 'tailwind-merge';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import SimpleSelect from '../select/SimpleSelect';
import { TextInput } from '../inputs/TextInput';
import { Counter } from '../counter/Counter';
import PhoneInput from '../inputs/PhoneInput';
import HourMinutePicker from '../hourPicker/HourMinutePicker';
import RadioGroup from '../radioGroup/RadioGroup';
import TextArea from '../inputs/TextArea';

export type FormFieldType =
  | 'text'
  | 'textarea'
  | 'counter'
  | 'simpleSelect'
  | 'phone'
  | 'hourPicker'
  | 'radioGroup'
  | 'password';

interface BaseInputProps {
  name?: string;
  value?: any;
  placeholder?: string;
  onChange?: (value: any) => void;
  onBlur?: (e: any) => void;
  classNames?: string;
  isDisabled?: boolean;
}

interface TextAreaProps extends BaseInputProps {
  type: 'textarea';
  textAreaLimit?: number;
}

interface CounterProps extends BaseInputProps {
  type: 'counter';
}

interface SelectInputProps extends BaseInputProps {
  type: 'simpleSelect';
  selectOptions: {
    id: string | number;
    value: string | number;
    label?: string;
  }[];
}

interface PhoneInputProps extends BaseInputProps {
  type: 'phone';
}

interface HourPickerProps extends BaseInputProps {
  type: 'hourPicker';
}

interface RadioGroupProps extends BaseInputProps {
  type: 'radioGroup';
  radioOptions: { id: string; label: string; value: string }[];
}

interface TextInputProps extends BaseInputProps {
  type: 'text' | 'password';
}

type InputProps =
  | TextInputProps
  | TextAreaProps
  | CounterProps
  | SelectInputProps
  | PhoneInputProps
  | HourPickerProps
  | RadioGroupProps;

interface FormFieldBaseProps {
  label?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  required?: boolean;
}

export type FormFieldProps = InputProps & FormFieldBaseProps;

/**
 * Input field component that is used to display input fields, textareas, counters, select inputs, phone inputs, hour pickers and radio groups
 * @param name - The name of the input field
 * @param value - The value of the input field
 * @param placeholder - The placeholder of the input field
 * @param onChange - The function to call when the input field value is changed
 * @param type - The type of the input field
 * @param classNames - The classes to be applied to the input field
 * @param isDisabled - The state of the input field
 * @param textAreaLimit - The limit of the text area
 * @param selectOptions - The options for the select input
 * @param radioOptions - The options for the radio group
 * @param onBlur - The function to call when the input field is blurred
 * @param label - The label of the input field
 * @param isInvalid - The state of the input field
 * @param errorMessage - The error message of the input field
 * @param required - The state of the input field
 * @returns Input field component
 */
const InputField: React.FC<InputProps> = memo((props) => {
  const { name, value, placeholder, onChange, type, classNames } = props;
  const t = useI18n();

  const handleEventChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  const handleValueChange = useCallback(
    (value: any) => {
      onChange?.(value);
    },
    [onChange]
  );

  switch (type) {
    case 'textarea':
      return (
        <TextArea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleValueChange}
          classNames={classNames}
          limit={props.textAreaLimit}
        />
      );
    case 'counter':
      return <Counter value={value} onChange={handleValueChange} />;
    case 'simpleSelect':
      return (
        <SimpleSelect
          name={name}
          value={value}
          placeholder={
            placeholder || t('component.form-field.select.placeholder')
          }
          onChange={handleValueChange}
          options={props.selectOptions}
          emptyLabel={t('select.isEmpty.general')}
        />
      );
    case 'phone':
      return (
        <PhoneInput
          value={value}
          onChange={handleValueChange}
          name={name}
          placeholder={placeholder}
        />
      );
    case 'hourPicker':
      return (
        <HourMinutePicker
          value={value}
          onChange={handleValueChange}
          name={name}
        />
      );
    case 'radioGroup':
      return (
        <RadioGroup
          value={value}
          options={props.radioOptions}
          onChange={handleValueChange}
          name={name}
        />
      );
    case 'text':
    case 'password':
      return (
        <TextInput
          name={name}
          classNames={classNames}
          placeholder={placeholder}
          value={value}
          onChange={handleEventChange}
          onBlur={props.onBlur}
          isPassword={type === 'password'}
        />
      );
    default:
      return (
        <TextInput
          name={name}
          classNames={classNames}
          placeholder={placeholder}
          value={value}
          onChange={handleEventChange}
        />
      );
  }
});

InputField.displayName = 'InputField';

const FormItem: React.FC<FormFieldProps> = ({
  name,
  label,
  errorMessage,
  classNames,
  required = false,
  isInvalid,
  ...props
}) => {
  return (
    <Flex className={twMerge('flex-col grow', classNames)}>
      {label && (
        <label
          htmlFor={name}
          className="text-primary font-semibold text-xs mb-2"
        >
          {label}
        </label>
      )}
      <InputField
        {...props}
        name={name}
        classNames={isInvalid ? 'border-red-500' : 'border-transparent'}
      />
      {errorMessage && (
        <Text className="text-red-500 text-xs font-medium mt-1">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

export default FormItem;
