import { twMerge } from 'tailwind-merge';
import Flex from '../flex/Flex';

type RadioGroupProps = {
  value: string;
  options?: { id: string; label: string; value: string }[];
  onChange: (value: string) => void;
  name?: string;
};

const RadioGroup = ({
  value,
  options = [],
  onChange,
  name,
}: RadioGroupProps) => {
  const checkedValue = value;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <fieldset>
      <input
        type="text"
        className="sr-only"
        id={name}
        value={checkedValue}
        onChange={() => {}}
      />
      <Flex className="sm:items-center sm:space-x-2 sm:space-y-0">
        {options.map((option, index) => (
          <div key={option.id} className="flex items-center ">
            <input
              checked={option.value.toString() === checkedValue.toString()}
              value={option.value.toString()}
              id={option.id}
              name={name}
              type="radio"
              className={twMerge(
                'h-6 w-6  accent-red-600 ',
                index !== 0 ? 'ml-4' : ''
              )}
              onChange={handleChange}
            />
            <label
              htmlFor={option.id}
              className="ml-2 block text-sm font-medium leading-6 text-gray-900"
            >
              {option.label}
            </label>
          </div>
        ))}
      </Flex>
    </fieldset>
  );
};
export default RadioGroup;
