import { ValidationError } from 'yup';
import * as yup from 'yup';

// check if the form is valid on client side and return the validation errors if not valid
export const transformYupErrorsIntoObject = (
  errors: ValidationError
): Record<string, string> => {
  const validationErrors: Record<string, string> = {};

  errors.inner.forEach((error) => {
    if (error.path !== undefined) {
      validationErrors[error.path] = error.errors[0];
    }
  });

  return validationErrors;
};

// validate the form object using the yup schema
export const validateFormObject = async (
  schema: yup.ObjectSchema<Record<string, string>>,
  formData: Record<string, string>
) => {
  let isValid = await schema?.isValid(formData);
  let validationErrors: Record<string, string> = {};

  // If the form is not valid, set the errors and return early without initiating the async action
  if (!isValid && schema) {
    try {
      await schema?.validate(formData, { abortEarly: false });
    } catch (error) {
      validationErrors = transformYupErrorsIntoObject(error as ValidationError);
    }
  }
  return { isValid, validationErrors };
};
